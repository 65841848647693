// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import {
  SEO,
  Title,
  Text,
  Intro,
} from "components";

import victoryImage from "../images/illustrazioni/vittoria.png"

const pageProperties = {
  title: "Bravo, form inviato con successo",
  //description: "Tranquillo. Mal che vada ti offriremo un caffè e ci faremo un gin tonic, o viceversa :)",
};

function ThankYouPage() {
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Ti ricontatteremo noi"
        description="Form inviato con successo"
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen ">
        <section className="px-8 md:px-64 justify-between contact-form mt-12 mb-24">
            <div className="mt-24 md:mt-48">
                <Title size={"small"}>{pageProperties.title}</Title>
                <Text>
                    Grazie per averci contattati. Ci faremo vivi al più presto!
                </Text>                
            </div>

            <div className="justify-center mt-36 ">
                <img class="mx-auto animate-bounce px-8 md:px-28" src={victoryImage} alt="vittoria" />
            </div>
        </section>
      </div>
    </CleanLayout>
  );
}

export default ThankYouPage;